import { Link } from "gatsby"
import React from "react"
import { Button } from "reactstrap"
import Notification from "../components/Notification/Notification"

const Footer = () => (
  <footer>
    <img class="logo" src="/RR_textured_logo_footer.png" alt="" />
    <ul class="social">
      <li class="social-item">
        <a
          class="social-link"
          href="https://www.instagram.com/raisedandrooted/"
          target="_blank"
        >
          <img src="/instagram-logo-white.svg" alt="Instagram" />
        </a>
      </li>
      <li class="social-item">
        <a
          class="social-link"
          href="https://www.facebook.com/raisedandrooted/"
          target="_blank"
        >
          <img src="/facebook-logo-white.svg" alt="Facebook" />
        </a>
      </li>
    </ul>

    <nav class="footer-navigation">
      <ul class="mr-auto navbar-nav">
        <li class="nav-item">
          <Link to="/about-our-ads/">About Our Ads</Link>
        </li>
        <li class="nav-item">
          <Link to="/privacy-policy/">Privacy Policy</Link>
        </li>
        <li class="nav-item">
          <Link to="/terms-of-use/">Terms of Use</Link>
        </li>
        <li class="nav-item">
          <a
            href="https://app.keysurvey.com/f/1463959/4ffd/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Do Not Sell My Personal Information
          </a>
        </li>
      </ul>
    </nav>
    <p class="legal">Copyright 2024 Raised & Rooted</p>
    <Notification
      idName="cookiesAlert"
      className={"notification--gdpr"}
      text="Your privacy is important to us. We use cookies to ensure you get the best experience on this website. For more information about how to control your cookies click learn more."
      buttonText={"Accept"}
      buttons={
        <Link to={"/privacy-policy/"}>
          <Button
            color={"Primary"}
            buttonColor={"Primary"}
            type="button"
            className={`notification__btn ml-3 position-relative`}
          >
            Learn More
          </Button>
        </Link>
      }
    />
  </footer>
)

export default Footer
