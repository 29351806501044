import React from "react"
import PropTypes from "prop-types"
import { Alert } from "reactstrap"
import { withCookies } from "react-cookie"
import classNames from "classnames"
import "../../scss/notification.scss"
import Button from "../Utility/button"

// Please use the correct export for this component in order to utilize cookies.
// ex: import Notification from "<library>"
// Alert.stories.js specifies the import to escape the cookie storage for UX purposes.
export class Notification extends React.Component {
  constructor(props) {
    super(props)
    const { cookies, cookieName } = props
    this.state = {
      closed: cookies && cookieName ? cookies.get(cookieName) : false,
    }
  }

  dismiss = () => {
    const { cookies, cookieName } = this.props

    if (cookies && cookieName) {
      cookies.set(cookieName, true, { path: "/" })
    }

    this.toggle()
  }
  decline = () => {
    const { cookies, cookieName } = this.props

    if (cookies && cookieName) {
      cookies.set(cookieName, false, { path: "/" })
    }

    this.toggle()
  }

  toggle = () => {
    this.setState({
      closed: !this.state.closed,
    })
  }

  render() {
    const alertClass = classNames(
      "notification fixed-bottom mb-0 border-0 p-0",
      {
        [`${this.props.className}`]: this.props.className,
        "notification--floating": this.props.floating,
        "notification--closed": this.state.closed,
      }
    )
    return (
      <Alert color={this.props.color} className={alertClass}>
        <div
          id="cookiesAlert"
          className="notification__container d-flex flex-wrap m-auto px-3 py-2 justify-content-center align-items-center"
        >
          <span className="notification__text">{this.props.text}</span>
          {/* <button type="button" className="notification__btn button">Learn&nbsp;More<a className="button" href="/privacy-policy/"></a></button> */}
          <a className="notification__btn" href="/privacy-policy/">
            Learn&nbsp;More
          </a>
          &nbsp;&nbsp;
          <button
            type="button"
            className="notification__btn"
            onClick={this.decline}
          > 
            {" "}
            Decline{" "}
          </button>
          <button
            type="button"
            className="notification__btn"
            onClick={this.dismiss}
          >
            {" "}
            Accept{" "}
          </button>
          {this.props.link && (
            <span className="notification__link position-absolute w-100 h-100">
              {this.props.link}
            </span>
          )}
        </div>
      </Alert>
    )
  }
}

export default withCookies(Notification)

Notification.propTypes = {
  /** Additional classes. */
  className: PropTypes.string,
  /** Background color. */
  color: PropTypes.string,
  /** Text to display in the notification. */
  text: PropTypes.string,
  /** Text to display in the button. */
  buttonText: PropTypes.string,
  /** Button color. */
  buttonColor: PropTypes.string,
  /** Additional button classes. */
  buttonClass: PropTypes.string,
  /** Indicates whether the notification will be floating. */
  floating: PropTypes.bool,
  /** Name to use for the cookie. The cookie will be set when the notification is closed. */
  cookieName: PropTypes.string,
  /** Link to use when clicking on the notification. */
  link: PropTypes.element,
}

Notification.defaultProps = {
  color: "Primary",
  text:
    "This website uses cookies to ensure you get the best experience on our website.",
  buttonColor: "Primary",
  floating: false,
  cookieName: "GDPR",
}
