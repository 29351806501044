import { Link } from "gatsby"
import React from "react"

class Header extends React.Component {
    state = { showMenu: false }

    toggleMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu
        })
    }

    render() {
        const menuActive = this.state.showMenu ? 'is-active' : '';
        const burgerActive = this.state.showMenu ? 'is-active' : '';

        return (
            <header>
                <nav role="navigation" class="navbar navbar-expand-xl">
                    <div class="container"><a aria-current="page" title="" class="navbar-brand" href="/">
                        <img class="logo" src="/Logo-Update-9.11.19.png" alt="" /></a>
                        <button class={`hamburger hamburger--spin ${burgerActive}`} type="button" aria-label="Menu" aria-controls="navigation" onClick={this.toggleMenu}><span class="hamburger-box"><span class="hamburger-inner"></span></span></button>
                        <div id="main-navigation" class={`navbar-menu ${menuActive}`}>
                            <ul class="navbar-nav">
                                <li class="nav-item"><Link to="/products/" activeClassName="active" partiallyActive={true}>Products</Link></li>
                                <li class="nav-item"><Link to="/where-to-buy/?MM=panel2" activeClassName="active" partiallyActive={true}>Where to Buy</Link></li>
                                <li class="nav-item"><Link to="/faqs/" activeClassName="active" partiallyActive={true}>FAQs</Link></li>
                                <li class="nav-item"><Link to="/about-us/" activeClassName="active" partiallyActive={true}>About Us</Link></li>
                                <li class="nav-item"><Link to="/contact-us/" activeClassName="active" partiallyActive={true}>Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        )
    }
};

export default Header
