import React from "react"

export default props => (
  <a
    className={`button ${props.className}`}
    href={props.buttonLink}
    style={{ color: props.buttonColor, backgroundColor: props.buttonBGColor }}
  >
    {props.buttonText}
  </a>
)
